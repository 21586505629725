import { Injectable } from "@angular/core";
import { BaseService } from "../base/base.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ClosePermit, Permit, WelfareCheck } from "@app/models/permit.model";
import { catchError, Observable, tap } from "rxjs";
import { environment } from "@env/environment";
import { LocalizationService } from "@app/components/internationalization/localization.service";


@Injectable({
    providedIn: 'root'
})

export class PermitService extends BaseService {
    //readonly BASE_URL = `https://localhost:7062/v1/gateway/permits/`;
    readonly BASE_URL = `${environment.gatewayAPI_V1}/permits/`;

    constructor(private httpClient: HttpClient,
        private _localizationService : LocalizationService) {
        super();
    }

    public getPermitWithFormBySignInSignOutId(signInSignOutId: string | undefined): Observable<Permit> {
        const url = `${this.BASE_URL}Permits/getPermitWithFormBySignInSignOutId`;
        let params = new HttpParams();

        if (signInSignOutId != null && signInSignOutId != undefined)
            params = params.append('id', signInSignOutId);

        return this.httpClient.get<Permit>(url, { params: params }).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getPermitBySignInSignOutId(signInSignOutId: string | undefined): Observable<Permit> {
        const url = `${this.BASE_URL}Permits/GetPermitBySignInSignOutId`;
        let params = new HttpParams();

        if (signInSignOutId != null && signInSignOutId != undefined)
            params = params.append('id', signInSignOutId);

        return this.httpClient.get<Permit>(url, { params: params }).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public createPermit(permit: Permit): Observable<Permit> {
        const url = `${this.BASE_URL}Permits/create`;
        
        return this.httpClient.post<Permit>(url, permit).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public draftPermit(permit: Permit): Observable<Permit> {
        const url = `${this.BASE_URL}Permits/DraftPermit`;

        return this.httpClient.put<Permit>(url, permit).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public submitPermit(permit: Permit): Observable<Permit> {
        const url = `${this.BASE_URL}Permits/SubmitPermit`;

        return this.httpClient.put<Permit>(url, permit).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public updatePermit(permit: Permit): Observable<Permit> {
        const url = `${this.BASE_URL}Permits/ClosePermit`;
        // const url = `https://localhost:44381/api/v1/Permits/ClosePermit`

        return this.httpClient.put<Permit>(url, permit).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getNewWelfareCheck(): Observable<WelfareCheck> {
        const url = `${this.BASE_URL}WelfareChecks/getNewWelfareCheck`;
        return this.httpClient.get<WelfareCheck>(url).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public createWelfareCheckAnswer(welfareCheck: WelfareCheck): Observable<WelfareCheck> {
        const url = `${this.BASE_URL}WelfareChecks/Create`;
        return this.httpClient.post<WelfareCheck>(url, welfareCheck).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getNewClosePermit(): Observable<ClosePermit> {
        const url = `${this.BASE_URL}ClosePermits/GetNewClosePermit`;
        // const url = `https://localhost:44381/api/v1/ClosePermits/GetNewClosePermit`;
        return this.httpClient.get<ClosePermit>(url).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    /**
     * Helper method to determine the contents of a question's isRequiredMessage.
     * @param questionIsRequiredMessage 
     *  String containing the IsRequiredMessage of a question
     * @returns 
     *  The default message if the questionIsRequiredMessage is empty, returns the original IsRequiredMessage otherwise
     */
    public determineIsRequiredMessage(questionIsRequiredMessage: string): string{
        if(questionIsRequiredMessage !== null && questionIsRequiredMessage !== ''){
            return questionIsRequiredMessage;
        }
        else{
            return this._localizationService.translate('permit_required_field_error_message');
        }
    }
}
